import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUrunPayDagilimKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUrunPayDagilimKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  setEserPayDagilimSave: number;
  setEserPayDagilimSave_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  sbEserSahibiList: any[];
  sbEserSahibiList_dummy: any[];
  sbCariList: any[];
  sbCariList_dummy: any[];
  sbYayimciList: any[];
  sbYayimciList_dummy: any[];
  sbMBirligiList: any[];
  sbMBirligiList_dummy: any[];
  getEserPayDagilimKartbyId: any[];
  getEserPayDagilimKartbyId_dummy: any[];
}

export class UrunPayDagilimKart_ScreenBase extends React.PureComponent<IUrunPayDagilimKart_ScreenProps, any> {
  urunpaydagilimkart_131824_value_kuikaSelectBoxRef: React.RefObject<any>;
  urunpaydagilimkart_602641_value_kuikaSelectBoxRef: React.RefObject<any>;
  urunpaydagilimkart_327310_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "340a79fa-b026-44ac-a1f3-b70b4c9c27f6",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 604367, PropertyName: "label", Value: "Kaydet" },
        { Id: 400481, PropertyName: "value", Value: "Eser Sahibi" },
        { Id: 131824, PropertyName: "placeholder", Value: "..." },
        { Id: 993411, PropertyName: "value", Value: "Görevi" },
        { Id: 980508, PropertyName: "placeholder", Value: "..." },
        { Id: 11299, PropertyName: "value", Value: "Eser Payı" },
        { Id: 807853, PropertyName: "value", Value: "Yayımcı" },
        { Id: 602641, PropertyName: "placeholder", Value: "..." },
        { Id: 91291, PropertyName: "value", Value: "M.Birliği" },
        { Id: 327310, PropertyName: "placeholder", Value: "..." },
        { Id: 979337, PropertyName: "value", Value: "MUZ (Mekanik Payı)" },
        { Id: 206244, PropertyName: "value", Value: "MUZ (Temsili Payı)" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.urunpaydagilimkart_131824_value_kuikaSelectBoxRef = React.createRef();
    this.urunpaydagilimkart_980508_value_kuikaTextInputRef = React.createRef();
    this.urunpaydagilimkart_602641_value_kuikaSelectBoxRef = React.createRef();
    this.urunpaydagilimkart_327310_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      setEserPayDagilimSave: 0,
      GoBack: "",
      sbEserSahibiList: [],
      sbCariList: [],
      sbYayimciList: [],
      sbMBirligiList: [],
      getEserPayDagilimKartbyId: []
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("urunpaydagilimkart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UrunPayDagilimKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("urunpaydagilimkart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("urunpaydagilimkart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UrunPayDagilimKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      urunpaydagilimkart_131824_value: this.state.getEserPayDagilimKartbyId?.at?.(0)?.dagitimYapilacakKodu ?? undefined,
      urunpaydagilimkart_980508_value: this.state.getEserPayDagilimKartbyId?.at?.(0)?.gorevi ?? undefined,
      urunpaydagilimkart_981175_value: this.state.getEserPayDagilimKartbyId?.at?.(0)?.hakedisOran ?? undefined,
      urunpaydagilimkart_602641_value: this.state.getEserPayDagilimKartbyId?.at?.(0)?.yayimciID ?? undefined,
      urunpaydagilimkart_327310_value: this.state.getEserPayDagilimKartbyId?.at?.(0)?.mBirligiID ?? undefined,
      urunpaydagilimkart_730302_value: this.state.getEserPayDagilimKartbyId?.at?.(0)?.muzOran ?? undefined,
      urunpaydagilimkart_422522_value: this.state.getEserPayDagilimKartbyId?.at?.(0)?.muzOranTemsili ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  UrunPayDagilimKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UrunPayDagilimKart/UrunPayDagilimKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbEserSahibiList = result?.data.sbEserSahibiList;

    formVars.urunpaydagilimkart_131824_value =
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].dagitimYapilacakKodu
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].dagitimYapilacakKodu
        : null;
    formVars.urunpaydagilimkart_131824_options = stateVars.sbEserSahibiList;
    stateVars.sbCariList = result?.data.sbCariList;
    stateVars.sbYayimciList = result?.data.sbYayimciList;

    formVars.urunpaydagilimkart_602641_value =
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].yayimciID
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].yayimciID
        : null;
    formVars.urunpaydagilimkart_602641_options = stateVars.sbYayimciList;
    stateVars.sbMBirligiList = result?.data.sbMBirligiList;

    formVars.urunpaydagilimkart_327310_value =
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].mBirligiID
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].mBirligiID
        : null;
    formVars.urunpaydagilimkart_327310_options = stateVars.sbMBirligiList;
    stateVars.getEserPayDagilimKartbyId = result?.data.getEserPayDagilimKartbyId;
    formVars.urunpaydagilimkart_131824_value =
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].dagitimYapilacakKodu
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].dagitimYapilacakKodu
        : null;
    formVars.urunpaydagilimkart_131824_options = stateVars.sbEserSahibiList;
    formVars.urunpaydagilimkart_980508_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].gorevi
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].gorevi
        : null
    );
    formVars.urunpaydagilimkart_981175_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].hakedisOran
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].hakedisOran
        : null
    );
    formVars.urunpaydagilimkart_602641_value =
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].yayimciID
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].yayimciID
        : null;
    formVars.urunpaydagilimkart_602641_options = stateVars.sbYayimciList;
    formVars.urunpaydagilimkart_327310_value =
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].mBirligiID
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].mBirligiID
        : null;
    formVars.urunpaydagilimkart_327310_options = stateVars.sbMBirligiList;
    formVars.urunpaydagilimkart_730302_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].muzOran
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].muzOran
        : null
    );
    formVars.urunpaydagilimkart_422522_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].muzOranTemsili
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].muzOranTemsili
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunPayDagilimKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunPayDagilimKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.urunpaydagilimkart_131824_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].dagitimYapilacakKodu
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].dagitimYapilacakKodu
        : null
    );

    stateVars.dataSource_131824 = this.state.sbEserSahibiList;
    stateVars.dataSource_131824 = this.state.sbEserSahibiList;
    formVars.urunpaydagilimkart_980508_value = ReactSystemFunctions.toString(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].gorevi
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].gorevi
        : null
    );

    formVars.urunpaydagilimkart_981175_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].hakedisOran
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].hakedisOran
        : null
    );

    formVars.urunpaydagilimkart_602641_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].yayimciID
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].yayimciID
        : null
    );

    stateVars.dataSource_602641 = this.state.sbYayimciList;
    stateVars.dataSource_602641 = this.state.sbYayimciList;
    formVars.urunpaydagilimkart_327310_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].mBirligiID
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].mBirligiID
        : null
    );

    stateVars.dataSource_327310 = this.state.sbMBirligiList;
    stateVars.dataSource_327310 = this.state.sbMBirligiList;
    formVars.urunpaydagilimkart_730302_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].muzOran
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].muzOran
        : null
    );

    formVars.urunpaydagilimkart_422522_value = ReactSystemFunctions.value(
      this,
      stateVars.getEserPayDagilimKartbyId?.length > 0
        ? stateVars.getEserPayDagilimKartbyId[0].muzOranTemsili
        : this.state.getEserPayDagilimKartbyId?.length > 0
        ? this.state.getEserPayDagilimKartbyId[0].muzOranTemsili
        : null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UrunPayDagilimKartComponent_604367_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      DagitimYapilacakKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkart_131824_value", "value", "sbEserSahibiList", "id", "id")
        ),
        "string"
      ),
      Gorevi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkart_980508_value", "value", "", "", "")
        ),
        "string"
      ),
      HakedisOran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkart_981175_value", "value", "", "", "")
        ),
        "number"
      ),
      MuzOran_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkart_730302_value", "value", "", "", "")
        ),
        "number"
      ),
      StokID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmStokID ?? this.props.screenInputs.prmstokid,
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      YayimciID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkart_602641_value", "value", "sbYayimciList", "id", "id")
        ),
        "Guid"
      ),
      MBirligiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkart_327310_value", "value", "sbMBirligiList", "id", "id")
        ),
        "Guid"
      ),
      MuzOranTemsili_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "urunpaydagilimkart_422522_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UrunPayDagilimKart/UrunPayDagilimKartComponent_604367_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setEserPayDagilimSave = result?.data.setEserPayDagilimSave;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UrunPayDagilimKartComponent_604367_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UrunPayDagilimKartComponent_604367_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UrunPayDagilimKartComponent_942682_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
